import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd'
import { Card } from '../../../Components/Card/Card'
import { UsersList } from './UsersList'
import { StyledButton } from '../../../styles'
import { ActivityHistory } from './ActivityHistory'

const { TabPane } = Tabs

export const Users = () => (
  <>
    <h1>Users</h1>
    <div style={{ margin: '30px 0' }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="users" key="1">
          <Card width="100%" padding="15px 30px">
            <Link to="/users/add">
              <StyledButton style={{ width: '150px' }}>
                Add new user
              </StyledButton>
            </Link>
            <UsersList />
          </Card>
        </TabPane>
        <TabPane tab="activity history" key="2">
          <Card width="100%" padding="15px 30px">
            <ActivityHistory />
          </Card>
        </TabPane>
      </Tabs>
    </div>
  </>
)
