export const SET_USER_DATA = 'SET_USER_DATA'
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA'

const userInitialState = null

export default (state = userInitialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...action.payload }
    case REMOVE_USER_DATA:
      return userInitialState
    default:
      return state
  }
}
