import moment from 'moment'
import { Card } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { axios } from '../../axios'
import { Image } from './Images'
import { StyledPlaceholder, StyledExcerpt } from './styles'
import { useDispatch } from 'react-redux'
import { SCREENSHOTS_ADD } from '../../store/reducers/screenshots'

export const ScreenshotCard = ({ screenshot, language, searchTerm }) => {
  const location = useLocation()
  const [url, setUrl] = useState(null)
  const mounted = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const refetch = (id) => {
      setTimeout(() => {
        axios({
          url: '/screenshot',
          method: 'GET',
          params: { task_id: id },
        }).then((response) => {
          if (mounted.current) {
            if (response.data.data.task_status === 'PENDING') {
              refetch(id)
            } else if (response.data.data.task_status === 'SUCCESS') {
              setUrl(response.data.data.task_result)
            }
          }
        })
      }, 1000)
    }

    axios({
      url: '/screenshot',
      method: 'POST',
      data: {
        page_id: screenshot.page_id,
        created_at: screenshot.created_at,
      },
    })
      .then((resp) => {
        const id = resp.data.data.task_id
        dispatch({ type: SCREENSHOTS_ADD, payload: id })
        refetch(id)
      })
      .catch(() => {})

    return () => {
      mounted.current = false
    }
  }, [])

  const parseContent = (screenshot) => {
    if (!screenshot || !screenshot.content) {
      return ''
    }

    return screenshot.content.match(/(\w+|<\/?em>)/g).join(' ')
  }

  if (searchTerm) {
    return (
      <Link to={{ pathname: `/snapshot/${screenshot.file}`, state: location }}>
        <Card
          style={{ marginBottom: 22 }}
          title={moment(screenshot.created_at).format('DD.MM.YYYY')}
          size="small"
          extra={
            screenshot &&
            screenshot.site &&
            screenshot.site.match(/https?[:]\/\/(www\.)?([^\/\t\n]*)\/?/i)[2]
          }
          hoverable
          headStyle={{ padding: '8px 22px', backgroundColor: '#F5F5F5' }}
          bodyStyle={{
            padding: 22,
          }}
        >
          <span
            style={{
              color: '#306cbf',
              fontSize: '28px',
              lineHeight: '32px',
              marginBottom: '1.2rem',
            }}
          >
            {screenshot && screenshot.title}
          </span>
          <StyledExcerpt
            dangerouslySetInnerHTML={{
              __html: parseContent(screenshot),
            }}
          />
          <Image url={url} expanded />
        </Card>
      </Link>
    )
  }

  return (
    <Link to={{ pathname: `/snapshot/${screenshot.file}`, state: location }}>
      <Card
        style={{ marginBottom: 22 }}
        title={language}
        size="small"
        extra={
          screenshot &&
          screenshot.site &&
          screenshot.site.match(/https?[:]\/\/(www\.)?([^\/\t\n]*)\/?/i)[2]
        }
        hoverable
        headStyle={{ padding: '8px 22px', backgroundColor: '#F5F5F5' }}
        bodyStyle={{
          width: 321,
          height: 364,
          padding: 22,
        }}
      >
        <span>
          {screenshot.lang ? (
            <img
              src={`${process.env.PUBLIC_URL}/flags/${screenshot.lang}.png`}
              alt={`${screenshot.lang}_flag`}
              style={{ margin: '0 20px 5px 0' }}
            />
          ) : (
            <StyledPlaceholder />
          )}
        </span>
        <span style={{ fontSize: 28 }}>
          {moment(screenshot.created_at).format('DD.MM.YYYY')}
        </span>
        <Image url={url} />
      </Card>
    </Link>
  )
}
