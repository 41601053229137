import React from 'react'
import styled from 'styled-components'
import { Button, Menu } from 'antd'

const { Item } = Menu

export const StyledNavbar = styled(Menu)`
  border-bottom: 1px solid #cdcdcd;
  height: 103px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`

export const StyledLogoContainer = styled(({ children, className }) => (
  <div className={className}>{children}</div>
))`
  height: 103px;
  border: none;
  margin-right: 50px;

  .logo {
    height: 70px;
    width: auto;
    transform: translateY(22%);
  }
`

export const StyledItem = styled(Item)`
  border-left: 1px solid #eeeeee;
  height: 103px;
  padding: 0 30px;
  text-transform: uppercase;
  line-height: 103px;

  a {
    color: #404040;
  }
`

export const StyledUserInfo = styled(({ children, className }) => (
  <div className={className}>{children}</div>
))`
  padding: 0 30px 0 220px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;

  height: 103px;
  text-transform: uppercase;
  line-height: 103px;

  a {
    text-transform: none;
  }
`

export const StyledButton = styled(Button)`
  .username {
    color: ${(props) => props.theme.link};
    margin-left: 3px;
  }
`
