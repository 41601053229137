import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useAxios } from '../../axios'
import { SET_USER_DATA } from '../../store/reducers/user'

export const Public = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const dispatch = useDispatch()
  const [{ data, error }] = useAxios({
    url: '/login_public',
    method: 'POST',
  })

  useEffect(() => {
    if (data) {
      localStorage.setItem('user', JSON.stringify(data))
      dispatch({ type: SET_USER_DATA, payload: data })
      setLoggedIn(true)
    }
  }, [data])

  if (error) {
    return 'Cannot login as a public user.'
  }

  return loggedIn ? <Redirect to="/" /> : 'Logging in as public user...'
}
