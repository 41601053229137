import React from 'react'
import moment from 'moment'
import { Button, Table } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { dateFormatter, findLangName } from '../helpers'
import { StyledTable } from '../../../styles'
import { Link } from 'react-router-dom'

const { Column } = Table

export const DomainInfo = ({ data }) => (
  <div>
    {data.languages.loading ? (
      <LoadingOutlined />
    ) : (
      <>
        {findLangName(data.languages.data, data.row.lang)}
        <span className="lighter">{` (${data.value})`}</span>
      </>
    )}
  </div>
)

const DateInfo = ({ value }) => (
  <>
    <div>{dateFormatter(value)}</div>
    <div>({value})</div>
  </>
)

export const ActivityHistoryList = ({ data }) => {
  const { history, languages } = data

  const onPaginationChange = (page) => {
    const { current, pageSize } = page
    history.setPagination({ ...history.pagination, current, pageSize })
  }

  return (
    <StyledTable
      onChange={onPaginationChange}
      dataSource={history.data}
      loading={history.loading}
      pagination={{
        position: ['bottomLeft'],
        pageSize: history.pagination.pageSize,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: history.total,
      }}
      style={{ marginTop: 40 }}
      bordered
    >
      <Column
        title="Date"
        dataIndex="date"
        key="date"
        render={(value) => moment.unix(value).format('DD.MM.YYYY HH:mm')}
      />
      <Column
        title="Username"
        dataIndex="username"
        key="username"
        render={(value, row) => (
          <Link to={`/settings?userid=${row.key}`}>
            <Button type="link">{value}</Button>
          </Link>
        )}
      />
      <Column title="IP Address" dataIndex="ip_address" key="ip_address" />
      <Column
        title="Site"
        dataIndex="domain"
        key="domain"
        render={(value, row) => <DomainInfo data={{ languages, value, row }} />}
      />
      <Column title="Action" dataIndex="activity_type" key="activity_type" />
      <Column
        title="Schedule"
        dataIndex="schedule_trigger"
        key="schedule_trigger"
        render={(value) => <DateInfo value={value} />}
      />
    </StyledTable>
  )
}
