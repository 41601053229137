import React from 'react'
import { useActivityData } from '../hooks.js'
import { ActivityHistoryList } from './ActivityHistoryList'
import { ActivityFilters } from './ActivityFilters'

export const ActivityHistory = () => {
  const {
    history,
    types,
    websites,
    languages,
    updateFilters,
  } = useActivityData()

  return (
    <>
      <ActivityFilters data={{ types, updateFilters, websites }} />
      <ActivityHistoryList data={{ history, languages }} />
    </>
  )
}
