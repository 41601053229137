import styled from 'styled-components'
import { Alert, Button, Form, Select } from 'antd'
import Check from '../../assets/radio-check.png'
import Checked from '../../assets/radio-checked.png'

export const StyledAlert = styled(Alert)`
  width: 50%;
  margin: 20px auto;
`

export const StyledForm = styled(Form)`
  margin-top: 25px;

  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-form-item-label {
    font-weight: bold;

    label {
      color: #4d4d4d;
      font-size: 14px;
    }
  }

  .user-input {
    width: 50%;
  }
`
export const StyledSelect = styled(Select)`
  margin: 20px 0;
`
export const StyledRadio = styled.div`
  cursor: pointer;
  display: inline-block;
  border: 1px solid #c6c6c6;
  font-weight: bold;
  border-radius: ${(props) => props.theme.borderRadius};
  background-clip: padding-box;
  box-shadow: ${(props) => props.theme.shadow};
  width: 150px;
  height: 50px;
  margin: 0 16px 18px 0;
  padding-left: 40px;
  position: relative;
  color: #404040;
  text-transform: uppercase;
  line-height: 48px;
  background: rgba(102, 102, 102, 0.5)
    linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(255, 255, 255, 0.05))
    repeat-x;
  background-color: ${(props) =>
    props.active ? '#fff' : 'rgba(102,102,102,.05)'};

  &::after {
    position: absolute;
    top: 6px;
    left: 10px;
    width: 20px;
    height: 20px;
    content: url(${(props) => (props.active ? Checked : Check)});
  }
`

export const StyledButton = styled(Button)`
  display: inline-block;
  padding: 1rem 2rem;
  font-weight: bold;
  box-shadow: ${(props) => props.theme.shadow};
  background-color: rgba(120, 120, 120, 0.02);
  background-image: linear-gradient(
    to bottom,
    rgba(30, 30, 30, 0.02),
    rgba(255, 255, 255, 0.02)
  );
  background-repeat: repeat-x;
  margin: 25px 0;

  span {
    text-transform: uppercase;
  }

  &.ant-btn {
    height: auto;
  }
`
