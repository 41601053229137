import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Layout } from 'antd'
import { StyledButton, StyledHeader, StyledIframe, StyledTitle } from './styles'
import { CaretLeftOutlined } from '@ant-design/icons'

const { Content } = Layout

const domain = process.env.NODE_ENV === 'production' ? window.env.SNAPSHOT_ROOT : process.env.REACT_APP_SNAPSHOT_ROOT


export const Snapshot = () => {
  const history = useHistory()
  const url = history.location.pathname.slice(10)
  const goBack = `/search/${history.location.state.search}`

  return (
    <Layout>
      <StyledHeader>
        {history.location.state ? (
          <Link to={goBack}>
            <StyledButton>
              <CaretLeftOutlined />
              back to the search results
            </StyledButton>
          </Link>
        ) : null}
        <StyledTitle>
          Currently you’re viewing the archived version of the website
        </StyledTitle>
      </StyledHeader>
      <Content>
        <StyledIframe src={`${domain}${url}`} allowFullScreen />
      </Content>
    </Layout>
  )
}
