import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NumberParam, useQueryParam } from 'use-query-params'
import { Slider as AntdSlider, Button } from 'antd'
import { CalendarOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { StyledSlider } from './styles'
import { SET_SEARCH_PARAMS } from '../../store/reducers/search'
import { getFormatted } from './helpers'

export const Slider = ({ data }) => {
  const dispatch = useDispatch()
  const start = useSelector((state) => getFormatted(state.search.date_start))
  const stop = useSelector((state) => getFormatted(state.search.date_stop))
  const [, setDateStart] = useQueryParam('date_start', NumberParam)
  const [, setDateStop] = useQueryParam('date_stop', NumberParam)

  const [calendar, setCalendar] = useState([])
  const [range, setRange] = useState([0, 0])

  useEffect(() => {
    if (data) {
      const calendarFrom = moment(data.bound_date_start)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'day')
      const calendarTo = moment(data.bound_date_stop)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'day')

      const currentDuration = calendarTo.diff(calendarFrom, 'days')

      const currentCalendar = new Array(currentDuration + 1)
        .fill(calendarFrom)
        .map((from, index) => {
          const current = moment(from).add(index, 'days')
          const date = current.format('YYYY-MM-DD')
          return {
            date,
            timestamp: current.format('X'),
            label: current.format('D'),
            month: current.format('MMMM'),
            included: data.included_dates && data.included_dates.includes(date),
          }
        })

      const sliderFrom = currentCalendar.findIndex((day) => day.date === start)
      const stopIndex = currentCalendar.findIndex((day) => day.date === stop)
      const sliderTo = stopIndex >= 0 ? stopIndex : currentCalendar.length - 1

      setCalendar(currentCalendar)
      setRange([sliderFrom, sliderTo])
    }
  }, [data])

  if (!data || !(data.bound_date_start && data.bound_date_stop)) {
    return null
  }

  const months = calendar.map((date) => date.month)
  const monthCalendar = [...new Set(months)].map((name) => ({
    name,
    length: months.filter((month) => month === name).length,
  }))

  const getPercentage = (value) => `${(100 / calendar.length) * value}%`

  const changeQuery = (values) => {
    const date_start = calendar[values[0]].timestamp
    const date_stop = calendar[values[1]].timestamp
    setDateStart(date_start)
    setDateStop(date_stop)
    dispatch({ type: SET_SEARCH_PARAMS, payload: { date_start, date_stop } })
    setRange(values)
  }

  return (
    <StyledSlider data-cy="date-slider">
      <div className="icon">
        <CalendarOutlined />
      </div>
      <div className="calendar">
        <AntdSlider
          tooltipVisible={false}
          range
          value={range}
          max={calendar.length - 1}
          onChange={setRange}
          onAfterChange={changeQuery}
        />
        <div
          className="selected"
          style={{
            left: getPercentage(range[0]),
            width: getPercentage(range[1] - range[0] + 1),
          }}
        />
        <div className="days">
          {calendar.map((day) => (
            <div key={uuid()} className="day">
              {day.included ? (
                <strong>{day.label}</strong>
              ) : (
                <span>{day.label}</span>
              )}
            </div>
          ))}
        </div>
        <div className="months">
          {monthCalendar.map((month) => (
            <div
              className="month"
              key={uuid()}
              style={{ width: getPercentage(month.length) }}
            >
              {month.name}
            </div>
          ))}
        </div>
      </div>
      <Button
        type="link"
        style={{ padding: '10px 0' }}
        onClick={() => changeQuery([0, calendar.length - 1])}
      >
        <CloseCircleOutlined />
        <span style={{ color: '#808080' }}>
          Reset selection to whole period
        </span>
      </Button>
    </StyledSlider>
  )
}
