import React from 'react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

export const CollapseButton = ({ collapsed }) =>
  collapsed ? (
    <span>
      <CaretDownOutlined style={{ marginRight: 5 }} />
      More options
    </span>
  ) : (
    <span>
      <CaretUpOutlined style={{ marginRight: 5 }} />
      Less options
    </span>
  )
