import styled from 'styled-components'
import { Button, Layout } from 'antd'

export const StyledIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 800px;
  height: calc(100vh - 60px);
`

export const StyledHeader = styled(Layout.Header)`
  height: 60px;
  background: #ebebeb;
  color: #111;
  box-shadow: ${(props) => props.theme.shadow};
  z-index: 1;
`

export const StyledButton = styled(Button)`
  background: ${(props) => props.theme.button};
  color: #fff;
  border: none;
  margin-right: 20px;
  text-transform: uppercase;

  &:hover {
    background: ${(props) => props.theme.buttonHover};
    color: #fff;
    border: none;
  }
`

export const StyledTitle = styled.h3`
  font-size: 15px;
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  display: inline-block;
`
