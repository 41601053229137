import React from 'react'
import { Col, Row } from 'antd'
import { ScreenshotCard } from './ScreenshotCard'
import { useLanguages } from '../../hooks'
import { getLanguageName } from '../../helpers'

export const ScreenshotList = ({ screenshotsList, searchTerm, addTask }) => {
  const languages = useLanguages()

  return (
    <Row gutter={14} style={{ marginTop: 40 }}>
      {screenshotsList.map((screenshot) => (
        <Col
          data-cy="screenshot-card"
          key={screenshot.key}
          span={searchTerm ? 12 : 8}
        >
          <ScreenshotCard
            screenshot={screenshot}
            language={getLanguageName(screenshot.lang, languages)}
            addTask={addTask}
            searchTerm={searchTerm}
          />
        </Col>
      ))}
    </Row>
  )
}
