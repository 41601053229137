import { Redirect, useLocation } from 'react-router-dom'
import React from 'react'
import { usePermissions } from '../../hooks'

export const PermissionsProvider = ({ children }) => {
  const [isAdmin, isScheduler] = usePermissions()
  const location = useLocation()
  const usersRestricted = !isAdmin && location.pathname.startsWith('/users')
  const schedulesRestricted =
    !isScheduler && location.pathname.startsWith('/schedules')

  if (usersRestricted || schedulesRestricted) {
    return <Redirect to="/" />
  }

  return children
}
