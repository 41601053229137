import React from 'react'
import { Input as AntdInput } from 'antd'
import styled from 'styled-components'

const StyledInput = styled(AntdInput)`
  margin: 4px 0;
  padding: ${(props) => (props['data-search'] ? null : '14px 12px')};
  font-size: ${(props) => (props['data-search'] ? null : '14px')};
  &:focus {
    background: #fafafa;
    border: 1px solid #999999;
  }
`

export const Input = ({ label, searchStyles, ...rest }) => {
  return (
    <div>
      <p style={{ margin: 0, fontSize: 12, color: '#808080' }}>{label}</p>
      <StyledInput data-search={searchStyles} {...rest} />
    </div>
  )
}
