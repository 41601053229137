import { v4 as uuid } from 'uuid'

export const USERS_UPDATE = 'USERS_UPDATE'

const initialState = { hash: '' }

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_UPDATE:
      return { hash: uuid() }
    default:
      return state
  }
}
