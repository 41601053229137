import React, { useState, useEffect } from "react";
import axios from "axios";
import { Layout } from 'antd';
import styled from 'styled-components';
import { GlobalOutlined } from '@ant-design/icons';

function AldiFooter() {
  const api_url = "/api/v1/projectinfo";
  const [versionInfo, setVersionInfo] = useState({});

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await axios.get(api_url);
        setVersionInfo(response.data);
      } catch (error) {
        console.error("Error fetching version info:", error);
      }
    };

    fetchVersion();
  }, []);

  const { APP_VERSION, PIPELINE_ID } = versionInfo;

  return (
    <StyledFooter>
      <GlobalOutlined />
      <strong>Aldi International</strong>©  {(new Date().getFullYear())} Aldi SÜD.
      <p>Version {APP_VERSION} Build {PIPELINE_ID}</p>
    </StyledFooter>
  );
}

const { Footer } = Layout;

const StyledFooter = styled(Footer)`
  border-top: 1px solid #cbcbcb;
  background: #ebebeb;
  width: 70%;
  margin: 200px auto 0 auto;
  strong {
    margin: 0 15px;
  }
  p {
    margin: 0 15px;
    float: right;
  }
`;

export { AldiFooter };