import moment from 'moment'
import { v4 as uuid } from 'uuid'

export const normalizeUsers = (entry) =>
  entry.map(({ id, ...rest }) => ({
    key: id,
    id,
    ...rest,
  }))

export const normalizeDomains = (entry) =>
  entry.map(({ id, ...rest }) => ({
    key: id,
    id,
    ...rest,
  }))

export const normalizeHistory = (entry) =>
  entry.map(({ user_id, ...rest }) => ({
    key: user_id,
    ...rest,
  }))

export const findLangName = (languages = [], id) =>
  languages.find((lang) => lang.code === id).name

export const dateFormatter = (value) => {
  let formatter = {
    every: '[every] ddd [at] HH:mm',
    daily: '[daily] HH:mm',
    once: '[once] DD.MM.YYYY HH:mm',
  }[value.split(' ')[0]]
  return formatter
    ? moment
        .utc(value, formatter + ' [UTC]')
        .local()
        .format(formatter)
    : ''
}

export const findSnapshotPermissions = (data, domain, key) =>
  data.find((perm) => (perm.name === domain ? perm[key] : false))

export const setPermissions = (row, state, permission, websites) =>
  websites.reduce(
    (acc, current) =>
      current.name === row.name
        ? [
            ...acc,
            {
              ...current,
              [permission]: state,
            },
          ]
        : [...acc, current],
    []
  )

export const addWebsite = (value, websitesData, settingsData) =>
  value.map((domain) => ({
    key: uuid(),
    id: websitesData && websitesData.find((site) => site.name === domain).id,
    name: domain,
    snapshot_access: settingsData
      ? findSnapshotPermissions(
          settingsData.domains_permissions,
          domain,
          'snapshot_access'
        )
      : false,
    schedule_manage: settingsData
      ? findSnapshotPermissions(
          settingsData.domains_permissions,
          domain,
          'schedule_manage'
        )
      : false,
  }))
