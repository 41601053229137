import React, { useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { Button, Col, Divider, Form, Row } from 'antd'
import { axios, useAxios } from '../../../axios'
import { addWebsite, setPermissions } from '../helpers'
import { Input } from '../../../Components/Form/Input'
import { WebsitesTable } from '../Users/WebsitesTable'
import { WebsitesSelect } from '../Users/WebsitesSelect'
import { StyledButton, StyledForm, StyledRadio } from '../styles'
import { initialState, reducer } from '../reducer'
import { SET_USER_DATA } from '../../../store/reducers/user'
import { USERS_UPDATE } from '../../../store/reducers/users'
import {
  SET_SUCCESS,
  SET_SUCCESS_MSG,
  SET_ERROR,
} from '../../../store/reducers/submission'

export const UserDataForm = ({ settingsData }) => {
  const [{ data: websitesData, loading }] = useAxios({
    url: '/domains',
    method: 'GET',
  })

  const history = useHistory()
  const reduxDispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [form] = Form.useForm()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { managing, access, websites, warcs } = state
  const isCurrentUser = settingsData ? user.id === settingsData.id : false

  const submit = (formData, managing_users, active, warc_access) => {
    // eslint-disable-next-line no-unused-vars
    const domains_permissions = websites.map(({ key, ...rest }) => rest)
    const payload = { ...formData, domains_permissions, managing_users, active, warc_access }
    const currentUserPayload = { ...user, ...payload }
    const selectedUserPayload = { ...settingsData, ...payload }

    if (isCurrentUser) {
      axios({
        url: `users/${user.id}`,
        method: 'PUT',
        data: selectedUserPayload,
      })
        .then((response) => {
          reduxDispatch({ type: SET_USER_DATA, payload: response.data })
          reduxDispatch({ type: SET_SUCCESS })
          reduxDispatch({
            type: SET_SUCCESS_MSG,
            payload: 'User successfully updated.',
          })
          reduxDispatch({ type: USERS_UPDATE })
          localStorage.setItem('user', JSON.stringify(response.data))
        })
        .catch((error) =>
          reduxDispatch({ type: SET_ERROR, payload: error.response.data.msg })
        )
    } else if (settingsData) {
      axios({
        url: `users/${settingsData.id}`,
        method: 'PUT',
        data: currentUserPayload,
      })
        .then(() => {
          reduxDispatch({ type: SET_SUCCESS })
          reduxDispatch({
            type: SET_SUCCESS_MSG,
            payload: 'User successfully updated.',
          })
          reduxDispatch({ type: USERS_UPDATE })
        })
        .catch((error) =>
          reduxDispatch({ type: SET_ERROR, payload: error.response.data.msg })
        )
    } else {
      axios({
        url: '/users',
        method: 'POST',
        data: payload,
      })
        .then(() => {
          reduxDispatch({
            type: SET_SUCCESS_MSG,
            payload: 'User successfully created.',
          })
          reduxDispatch({ type: SET_SUCCESS })
          reset()
          history.goBack()
        })
        .catch((error) =>
          reduxDispatch({ type: SET_ERROR, payload: error.response.data.msg })
        )
    }
  }

  const reset = () => {
    form.resetFields()
    dispatch({ type: 'reset' })
  }

  const initializeWebsitesSettings = (options) => {
    const initialSettings = options.map(({ name, ...rest }) => ({
      key: uuid(),
      id: websitesData && websitesData.find((site) => site.name === name).id,
      name,
      ...rest,
    }))

    dispatch({ type: 'set_websites', payload: initialSettings })
  }

  useEffect(() => {
    if (settingsData) {
      initializeWebsitesSettings(settingsData.domains_permissions)
      dispatch({ type: 'set_managing', payload: settingsData.managing_users })
      dispatch({ type: 'set_access', payload: settingsData.active })
      dispatch({ type: 'set_warc_access', payload: settingsData.warc_access })
    }
    form.resetFields()
  }, [settingsData])

  const onSelectChange = (value) => {
    const payload = addWebsite(value, websitesData, settingsData)
    dispatch({ type: 'set_websites', payload })
  }

  const onPermissionChange = (row, state, permission) => {
    const payload = setPermissions(row, state, permission, websites)
    dispatch({ type: 'set_websites', payload })
  }

  return (
    <>
      <StyledForm
        form={form}
        onFinish={(data) => submit(data, managing, access, warcs)}
        name="add"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={settingsData}
      >
        <Form.Item
          name="username"
          label="Username"
          rules={[{ required: true, message: 'Please input username' }]}
        >
          <Input className="user-input" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: Boolean(!settingsData),
              message: 'Please input password',
            },
          ]}
        >
          <Input type="password" className="user-input" />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            { required: true, message: 'Please input your e-mail address' },
          ]}
        >
          <Input type="email" className="user-input" />
        </Form.Item>

        <Divider />

        <Row gutter={10}>
          <Col span={6} align="right">
            <p className="falsy-label">Websites:</p>
            <span className="small">Add websites and set permissions</span>
          </Col>
          <Col span={18}>
            <WebsitesTable
              websites={websites}
              onPermissionChange={onPermissionChange}
            />
            <WebsitesSelect
              onSelectChange={onSelectChange}
              value={websites.map((site) => site.name)}
              data={websitesData}
              loading={loading}
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={10}>
          <Col span={6} align="right">
            <p className="falsy-label">Managing Users</p>
          </Col>
          <Col span={18}>
            <StyledRadio
              onClick={() => dispatch({ type: 'set_managing', payload: true })}
              active={managing}
            >
              Allow
            </StyledRadio>
            <StyledRadio
              onClick={() => dispatch({ type: 'set_managing', payload: false })}
              active={!managing}
            >
              Deny
            </StyledRadio>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={6} align="right">
            <p className="falsy-label">Access Privileges</p>
            <span className="small">Determine if user can log in or not</span>
          </Col>
          <Col span={18}>
            <StyledRadio
              onClick={() => dispatch({ type: 'set_access', payload: true })}
              active={access}
            >
              Active
            </StyledRadio>
            <StyledRadio
              onClick={() => dispatch({ type: 'set_access', payload: false })}
              active={!access}
            >
              Inactive
            </StyledRadio>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={6} align="right">
            <p className="falsy-label">WARC Access</p>
            <span className="small">Determine if user should see warc archives</span>
          </Col>
          <Col span={18}>
            <StyledRadio
              onClick={() => dispatch({ type: 'set_warc_access', payload: true })}
              active={warcs}
            >
              Allow
            </StyledRadio>
            <StyledRadio
              onClick={() => dispatch({ type: 'set_warc_access', payload: false })}
              active={!warcs}
            >
              Deny
            </StyledRadio>
          </Col>
        </Row>

        <Form.Item style={{ margin: '0 auto', textAlign: 'center' }}>
          <StyledButton htmlType="submit">
            {settingsData ? 'Update Data' : 'Create New User'}
          </StyledButton>
          <Button
            onClick={() => history.goBack()}
            style={{ fontSize: 16 }}
            type="link"
          >
            cancel
          </Button>
        </Form.Item>
      </StyledForm>
    </>
  )
}