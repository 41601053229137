import React from 'react'
import { Col, Radio, Row } from 'antd'
import { useDispatch } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import { SET_SEARCH_PARAMS } from '../../store/reducers/search'

export const PageTitle = ({ data, loading, query, sorting, setSorting }) => {
  const dispatch = useDispatch()
  const onSortingChange = (e) => {
    setSorting(e.target.value)
    dispatch({ type: SET_SEARCH_PARAMS, payload: { sort: e.target.value } })
  }
  return (
    <Row style={{ marginTop: 40 }} align="bottom" justify="space-between">
      {loading ? (
        <LoadingOutlined style={{ fontSize: 30, color: '#2A60AB' }} />
      ) : (
        <>
          <Col>
            <h1 style={{ display: 'inline-block' }}>
              {data
                ? `${data && data.num_results} result${
                    data.objects.length === 1 ? '' : 's'
                  }`
                : ''}
            </h1>
            <span style={{ marginLeft: 10 }}>
              {`(from ${moment
                .unix(query.date_start)
                .format('YYYY.MM.DD')} to ${moment
                .unix(query.date_stop)
                .format('YYYY.MM.DD')})`}
            </span>
          </Col>
          <Col>
            <Radio.Group
              value={sorting}
              buttonStyle="outline"
              onChange={onSortingChange}
            >
              <Radio.Button value="relevant">Relevant first</Radio.Button>
              <Radio.Button value="newest">Newest first</Radio.Button>
            </Radio.Group>
          </Col>
        </>
      )}
    </Row>
  )
}
