import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from 'antd'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { CLEAR } from '../../store/reducers/submission'

const StyledContainer = styled.div`
  position: fixed;
  top: 100px;
  z-index: 100;
  width: 100%;
  transition: 0.5s opacity;
  opacity: ${(props) => (props.active ? 1 : 0)};

  .anticon-check-circle {
    color: ${(props) => props.theme.successBorder};
  }

  .anticon-close-circle {
    color: ${(props) => props.theme.error};
  }
`

const StyledSuccess = styled(Alert)`
  border: 1px solid ${(props) => props.theme.successBorder};
  background-color: ${(props) => props.theme.success};
  margin: 0 auto;
  width: 1000px;
`

const StyledError = styled(Alert)`
  border: 1px solid ${(props) => props.theme.error};
  background-color: ${(props) => props.theme.errorLight};
  margin: 0 auto;
  width: 1000px;
`

export const SubmissionFeedback = () => {
  const [active, setActive] = useState(false)
  const { success, successMsg, error, message } = useSelector(
    (state) => state.submission
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      setActive(true)
    } else if (success) {
      setActive(true)
    }
  }, [success, error])

  useEffect(() => {
    if (error || success) {
      let timeout = setTimeout(() => dispatch({ type: CLEAR }), 3000)
      return () => clearTimeout(timeout)
    }
  }, [error, success])

  useEffect(() => {
    if (active) {
      let timeout = setTimeout(() => {
        setActive(false)
      }, 2000)
      return () => clearTimeout(timeout)
    }
  }, [active])

  if (error) {
    return (
      <StyledContainer active={active}>
        <StyledError
          message="Error"
          description={message}
          type="error"
          showIcon
        />
      </StyledContainer>
    )
  }

  if (success) {
    return (
      <StyledContainer active={active}>
        <StyledSuccess
          message="Success"
          description={successMsg}
          type="success"
          showIcon
        />
      </StyledContainer>
    )
  }

  return <StyledContainer />
}
