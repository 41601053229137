import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row, Switch, Table } from 'antd'
import { axios, useAxios } from '../../../axios'
import { StyledButton } from '../styles'
import { normalizeDomains, setPermissions } from '../helpers'
import {
  SET_ERROR,
  SET_SUCCESS,
  SET_SUCCESS_MSG,
} from '../../../store/reducers/submission'
import { StyledTable } from '../../../styles'

const { Column } = Table

export const Domains = () => {
  const [{ data, loading }] = useAxios({
    url: '/domains',
    method: 'GET',
  })

  const [domains, setDomains] = useState([])
  const reduxDispatch = useDispatch()

  useEffect(() => {
    data && setDomains(data)
  }, [data])

  const onStatusChange = (row, value) => {
    const newState = setPermissions(row, !!value, 'status', domains)
    setDomains(newState)
  }

  const submit = () => {
    axios({
      url: '/domains',
      method: 'PUT',
      data: domains,
    })
      .then(() => {
        reduxDispatch({ type: SET_SUCCESS })
        reduxDispatch({
          type: SET_SUCCESS_MSG,
          payload: 'Domains data has been properly updated',
        })
      })
      .catch((error) =>
        reduxDispatch({ type: SET_ERROR, payload: error.response.data.msg })
      )
  }

  return (
    <>
      <Row gutter={10}>
        <Col span={6} align="right" style={{ marginTop: 34 }}>
          <p className="falsy-label">Domains</p>
          <span className="small">Manage domain activity</span>
        </Col>
        <Col span={18}>
          <StyledTable
            dataSource={data && normalizeDomains(data)}
            loading={loading}
            pagination={false}
            style={{ marginTop: 40 }}
            bordered
          >
            <Column title="Domain" dataIndex="name" key="name" />
            <Column
              title="Active"
              dataIndex="status"
              key="status"
              render={(value, row) => (
                <Switch
                  defaultChecked={!!value}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(newValue) => onStatusChange(row, newValue)}
                />
              )}
            />
          </StyledTable>

          <StyledButton
            onClick={submit}
            style={{ fontSize: 16, marginTop: 40 }}
          >
            Update Data
          </StyledButton>
        </Col>
      </Row>
    </>
  )
}
