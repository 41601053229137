import { combineReducers } from 'redux'
import user from './user.js'
import users from './users'
import submission from './submission'
import search from './search'
import screenshots from './screenshots'

const rootReducer = combineReducers({
  user,
  users,
  search,
  submission,
  screenshots,
})

export default rootReducer
