import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card } from '../../Components/Card/Card'
import { SearchForm } from './SearchForm'
import { StyledButton } from './styles'
import { Button } from 'antd'
import { CollapseButton } from './CollapseButton'
import { useInitialValues, useSearchQuery } from './hooks'
import { CLEAR_SEARCH_PARAMS } from '../../store/reducers/search'

export const Search = () => {
  const dispatch = useDispatch()
  const [collapsed, setCollapse] = useState(true)
  const url = useSearchQuery()
  const initialValues = useInitialValues()

  useEffect(() => {
    dispatch({ type: CLEAR_SEARCH_PARAMS })
  }, [])

  return (
    <>
      <div style={{ textAlign: 'center', padding: '50px 0 10px 0' }}>
        <h1>Aldi Wayback Search</h1>
        <p>Look up for past versions of Aldi’s website</p>
      </div>
      <Card width="100%" padding="20px 30px 10px 30px" margin="30px 0 6px 0">
        <SearchForm collapsed={collapsed} initialValues={initialValues} />
      </Card>
      <Button
        data-cy="collapse"
        style={{ padding: '0 0 0 30px' }}
        onClick={() => setCollapse(!collapsed)}
        type="link"
      >
        <CollapseButton collapsed={collapsed} />
      </Button>
      <div style={{ margin: '0 auto', width: '30%' }}>
        <Link to={`/search?${url}`}>
          <StyledButton>look up website</StyledButton>
        </Link>
      </div>
    </>
  )
}
