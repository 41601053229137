import React from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { DomainInfo } from '../Users/ActivityHistoryList'
import { useUserActivityData } from '../hooks'
import { StyledTable } from '../../../styles'

const { Column } = Table

export const ActivityHistory = ({ settingsData }) => {
  const { id } = settingsData
  const { snapshots, languages } = useUserActivityData(id)

  return (
    <StyledTable
      dataSource={snapshots.data}
      loading={snapshots.loading}
      pagination={{
        position: ['bottomLeft'],
        pageSize: 50,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
      }}
      style={{ marginTop: 40 }}
      bordered
    >
      <Column
        title="Site"
        dataIndex="domain"
        key="domain"
        render={(value, row) => <DomainInfo data={{ languages, value, row }} />}
      />
      <Column
        title="Started"
        dataIndex="start_time"
        key="start_time"
        render={(value) =>
          value ? moment.unix(value).format('YYYY/MM/DD hh:mm A') : '-'
        }
      />
      <Column
        title="Ended"
        dataIndex="stop_time"
        key="stop_time"
        render={(value) =>
          value ? moment.unix(value).format('YYYY/MM/DD hh:mm A') : '-'
        }
      />
      <Column title="Type" dataIndex="type" key="type" />
      <Column title="Status" dataIndex="status" key="status" />
      <Column
        title="Scheduled by"
        dataIndex="scheduled_by"
        key="scheduled_by"
      />
    </StyledTable>
  )
}
