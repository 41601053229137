import { useDispatch } from 'react-redux'

import { useLocalStorage } from '../../hooks'
import { SET_USER_DATA } from '../../store/reducers/user'

export const UserProvider = ({ children }) => {
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()

  if (user) {
    dispatch({ type: SET_USER_DATA, payload: user })
  }

  return children
}
