import { useEffect, useState } from 'react'
import { useAxios } from '../../axios'
import { addKey } from './helpers'

export const useSchedules = () => {
  const initial = { page: 1, per_page: 10 }
  const [paginationState, setPaginationState] = useState(initial)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(null)
  const [{ data: schedules, loading, error }, refetch] = useAxios({
    url: '/schedules',
    method: 'GET',
    params: paginationState,
  })

  const [historyPaginationState, setHistoryPaginationState] = useState(initial)
  const [historyData, setHistoryData] = useState([])
  const [historyTotal, setHistoryTotal] = useState(null)
  const [
    { data: snapshotsHistory, loading: historyLoading, error: historyError },
    historyRefetch,
  ] = useAxios({
    url: '/snapshots',
    method: 'GET',
    params: historyPaginationState,
  })

  useEffect(() => {
    refetch()
  }, [paginationState])

  useEffect(() => {
    if (schedules) {
      setData(addKey(schedules.objects))
      setTotal(schedules.num_results)
    }
  }, [schedules])

  useEffect(() => {
    if (snapshotsHistory) {
      setHistoryData(addKey(snapshotsHistory.objects))
      setHistoryTotal(snapshotsHistory.num_results)
    }
  }, [snapshotsHistory])

  return {
    schedules: {
      data,
      loading,
      error,
      refetch,
      total,
      paginationState,
      setPaginationState,
    },
    history: {
      historyData,
      historyLoading,
      historyError,
      historyTotal,
      historyPaginationState,
      setHistoryPaginationState,
      historyRefetch,
    },
  }
}
