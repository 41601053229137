import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Col, Pagination, Row } from 'antd'
import { v4 as uuid } from 'uuid'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params'
import { axios, useAxios } from '../../axios'
import { SearchForm } from './SearchForm'
import { StyledButtonSmall } from './styles'
import { CollapseButton } from './CollapseButton'
import { PageTitle } from './PageTitle'
import { ScreenshotList } from './ScreenshotsList'
import { useInitialValues, useSearchQuery } from './hooks'
import { NoResults } from './NoResults'
import { Loading } from './Loading'
import { Slider } from './Slider'
import { SET_SEARCH_PARAMS } from '../../store/reducers/search'
import { SCREENSHOTS_REMOVE } from '../../store/reducers/screenshots'

export const SearchResults = () => {
  const [collapsed, setCollapse] = useState(true)
  const [screenshotsList, setScreenshotsList] = useState([])
  const [loadingScreenshots, setLoadingScreenshots] = useState(false)
  const [noResults, setNoResults] = useState(false)
  const queued = useSelector((state) => state.screenshots)
  const dispatch = useDispatch()

  const [currentPageParam, setCurrentPageParam] = useQueryParam(
    'page',
    NumberParam
  )
  const [sortingParam, setSortingParam] = useQueryParam('sort', StringParam)

  const [query] = useQueryParams({
    domains: StringParam,
    date_start: NumberParam,
    date_stop: NumberParam,
    phrase: StringParam,
    page_area: StringParam,
    lang: StringParam,
    file_type: StringParam,
    page: NumberParam,
    sort: StringParam,
  })
  const initialValues = useInitialValues(query)
  const url = useSearchQuery()

  const [{ data, loading }] = useAxios({
    url: '/search',
    method: 'GET',
    params: query,
  })

  useEffect(() => {
    dispatch({ type: SET_SEARCH_PARAMS, payload: query })
  }, [])

  useEffect(() => {
    if (data && data.objects.length) {
      setLoadingScreenshots(false)
      setNoResults(false)
      setScreenshotsList(data.objects.map((obj) => ({ ...obj, key: uuid() })))
    } else if (data && !data.objects.length) {
      setLoadingScreenshots(false)
      setScreenshotsList([])
      setNoResults(true)
    }
  }, [data])

  const onPaginationChange = (page) => {
    setCurrentPageParam(page)
    axios({
      url: '/screenshot',
      method: 'DELETE',
      data: queued,
    })
    dispatch({ type: SCREENSHOTS_REMOVE })
  }

  return (
    <>
      <Row gutter={50}>
        <Col span={18}>
          <SearchForm
            collapsed={collapsed}
            results
            initialValues={initialValues}
          />
        </Col>
        <Col span={6}>
          <Link to={`/search?${url}`}>
            <StyledButtonSmall>look up website</StyledButtonSmall>
          </Link>
        </Col>
      </Row>
      <Button onClick={() => setCollapse(!collapsed)} type="link">
        <CollapseButton collapsed={collapsed} />
      </Button>
      <PageTitle
        data={data}
        loading={loading}
        query={query}
        sorting={sortingParam}
        setSorting={setSortingParam}
      />
      <Loading loading={loadingScreenshots} />
      {data && <Slider data={data} />}
      {screenshotsList.length ? (
        <ScreenshotList
          screenshotsList={screenshotsList}
          searchTerm={!!query.phrase}
        />
      ) : null}
      {noResults ? <NoResults /> : null}
      {screenshotsList.length ? (
        <Pagination
          data-cy="search-results-pagination"
          style={{ marginTop: 20 }}
          showSizeChanger={false}
          showQuickJumper
          defaultCurrent={1}
          current={currentPageParam}
          total={data && data.num_results}
          onChange={onPaginationChange}
        />
      ) : null}
    </>
  )
}
