import moment from 'moment'

export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS'

const initialState = {
  domains: ['all'],
  page: 1,
  sort: 'relevant',
  date_start: moment().subtract(1, 'months').format('X'),
  date_stop: moment().format('X'),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_PARAMS:
      return { ...state, ...action.payload }
    case CLEAR_SEARCH_PARAMS:
      return initialState
    default:
      return state
  }
}
