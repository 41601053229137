import { useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAxios } from './axios'
import { useSelector } from 'react-redux'

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export const useQuery = () => new URLSearchParams(useLocation().search)

export const useLanguages = () => {
  const [languages, setLanguages] = useState([])
  const [{ data }] = useAxios({
    url: '/languages',
    method: 'GET',
  })

  useEffect(() => {
    if (data) {
      setLanguages(data)
    }
  }, [data])

  return languages
}

export const useDomains = () => {
  const [domains, setDomains] = useState([])
  const permissions = useSelector((state) => state.user.domains_permissions)
  const [{ data }] = useAxios({
    url: '/domains',
    method: 'GET',
  })

  useEffect(() => {
    if (data && permissions) {
      const permissionNames = permissions.map((site) => site.name)
      const availableDomains = data.filter((domain) =>
        permissionNames.includes(domain.name)
      )
      setDomains(availableDomains)
    }
  }, [permissions, data])

  const scheduleDomains = useMemo(
    () =>
      domains.filter((domain) =>
        permissions.some(
          (perm) => perm.name === domain.name && perm.schedule_manage
        )
      ),
    [domains, permissions]
  )
  const snapshotDomains = useMemo(
    () =>
      domains.filter((domain) =>
        permissions.some(
          (perm) => perm.name === domain.name && perm.snapshot_access
        )
      ),
    [domains, permissions]
  )

  return { domains, scheduleDomains, snapshotDomains }
}

export const usePermissions = () => {
  const user = useSelector(({ user }) => user)

  const hasAnyPermissions = (user) =>
    user.domains_permissions
      .map((permission) => permission.schedule_manage)
      .filter((x) => x).length

  const isAdmin = user ? user.managing_users : false
  const isScheduler = user ? hasAnyPermissions(user) : false
  const warcAccess = user ? user.warc_access : false

  return [isAdmin, isScheduler, warcAccess]
}
