import React from 'react'
import { StyledCard } from './styles.js'

export const Card = ({ width, padding, margin, children }) => {
  return (
    <StyledCard width={width} padding={padding} margin={margin}>
      {children}
    </StyledCard>
  )
}
