import React from 'react'
import { Select as AntdSelect } from 'antd'
import styled from 'styled-components'

const StyledSelect = styled(AntdSelect)`
  padding: 4px 0;
  font-size: 16px;
`

export const Select = ({ label, ...rest }) => (
  <>
    <p style={{ margin: 0, fontSize: 12, color: '#808080' }}>{label}</p>
    <StyledSelect {...rest} />
  </>
)
