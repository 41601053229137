import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Card } from '../../Components/Card/Card'
import { LoginForm } from './LoginForm'
import { SET_USER_DATA } from '../../store/reducers/user'
import { StyledLogoContainer } from './styles'
import { axios } from '../../axios'
import logo from '../../assets/logo.png'

export const Login = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [isError, setError] = useState(false)

  const login = (formData) => {
    axios({
      url: '/login',
      method: 'post',
      data: formData,
    })
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data))
        dispatch({ type: SET_USER_DATA, payload: response.data })
      })
      .catch((error) => setError(error.response.data))
  }

  return user ? (
    <Redirect to="/" />
  ) : (
    <>
      <StyledLogoContainer>
        <img src={logo} alt="Aldi Logo" />
        <h1>Aldi Wayback</h1>
        <span>Look up for past versions of Aldi’s website</span>
      </StyledLogoContainer>
      <Card width="500px" padding="60px 50px" margin="30px auto">
        <LoginForm login={login} error={isError} />
      </Card>
    </>
  )
}
