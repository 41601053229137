import styled from 'styled-components'

export const StyledCard = styled.div`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  background-color: #f9f9f9;
  border-radius: ${(props) => props.theme.borderRadius};
  border: ${(props) => props.theme.border};
  box-shadow: ${(props) => props.theme.shadow};
  padding: ${(props) => props.padding};
`
