export const SET_SUCCESS = 'SET_SUCCESS'
export const SET_SUCCESS_MSG = 'SET_SUCCESS_MSG'
export const REMOVE_SUCCESS = 'REMOVE_SUCCESS'
export const SET_ERROR = 'SET_ERROR'
export const REMOVE_ERROR = 'REMOVE_ERROR'
export const CLEAR = 'CLEAR'

const initialState = {
  success: false,
  successMsg: '',
  error: false,
  message: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESS:
      return { ...state, success: true, error: false }
    case SET_SUCCESS_MSG:
      return { ...state, successMsg: action.payload }
    case SET_ERROR:
      return { ...state, success: false, error: true, message: action.payload }
    case CLEAR:
    case REMOVE_ERROR:
    case REMOVE_SUCCESS:
      return initialState
    default:
      return state
  }
}
