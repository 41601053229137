import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, DatePicker, Form, Radio, Row, Select as AntdSelect } from 'antd'
import moment from 'moment'
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { UnmountClosed } from 'react-collapse'
import { Select } from '../../Components/Form/Select'
import { Input } from '../../Components/Form/Input'
import { StyledCol, StyledForm, StyledRadioGroup } from './styles'
import { useAxios } from '../../axios'
import { SET_SEARCH_PARAMS } from '../../store/reducers/search'
import { useDomains } from '../../hooks'
import { unique } from './helpers'

const { Option } = AntdSelect
const { RangePicker } = DatePicker

export const SearchForm = ({ collapsed, results, initialValues }) => {
  const [{ data: languages }] = useAxios({
    url: '/languages',
    method: 'GET',
  })

  const { snapshotDomains } = useDomains()

  const [matchedLanguages, setMatchedLanguages] = useState([])
  const findLanguages = (codes) =>
    languages.filter((lang) => unique(codes).includes(lang.code))

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [areaConditions, setAreaConditions] = useState({
    term: initialValues.term,
    type: initialValues.type,
  })

  useEffect(() => {
    const getInitialLanguages = () => {
      if (!languages || !languages.length) {
        return []
      }

      const initialLanguages = unique(
        snapshotDomains.map((domain) => domain.languages).flat()
      )

      return languages.filter((lang) => initialLanguages.includes(lang.code))
    }

    if (!results) {
      setMatchedLanguages(getInitialLanguages())
      return
    }

    const availableLanguages =
      snapshotDomains &&
      snapshotDomains
        .filter((domain) => initialValues.websites.includes(domain.name))
        .map((domain) => domain.languages)
        .flat()

    if (
      initialValues.websites[0] !== 'all' &&
      snapshotDomains.length &&
      availableLanguages.length
    ) {
      setMatchedLanguages(findLanguages(availableLanguages))
    } else {
      setMatchedLanguages(getInitialLanguages())
    }
  }, [snapshotDomains, languages])

  const onChangeTermAndType = () => {
    setAreaConditions(form.getFieldsValue(['term', 'type']))
  }
  const noPhrase = !areaConditions.term
  const blockedType =
    areaConditions.type === 'doc' || areaConditions.type === 'pdf'
  const areaDisabled = noPhrase || blockedType

  const hasWebsites = (websites) =>
    websites.length === 1 && websites[0] === 'all'

  const onFinish = () => {
    form.resetFields()
  }

  const onDomainChange = (selectedSites) => {
    const current = selectedSites[selectedSites.length - 1]
    if (current === 'all') {
      const allLanguages = unique(
        snapshotDomains.map((domain) => domain.languages).flat()
      )

      setMatchedLanguages(findLanguages(allLanguages))
      return [current]
    } else {
      const selectedDomains = snapshotDomains.filter((domain) =>
        selectedSites.includes(domain.name)
      )
      const domainsLanguages = selectedDomains
        .map((domain) => domain.languages)
        .flat()

      setMatchedLanguages(findLanguages(domainsLanguages))
      return selectedSites.filter((site) => site !== 'all')
    }
  }

  const onValuesChange = (changed, all) => {
    const { period, websites, type, language, area, term } = all
    const params = {
      date_start: moment(period[0]).format('X'),
      date_stop: moment(period[1]).format('X'),
      domains: hasWebsites(websites) ? null : websites,
      file_type: type !== 'all' ? type : null,
      lang: language !== 'all' ? language : null,
      page_area: area === 'special_buys' ? area : null,
      phrase: term || null,
      sort: 'relevant',
    }

    dispatch({ type: SET_SEARCH_PARAMS, payload: params })
  }

  useEffect(() => {
    form.resetFields()
  }, [])

  useEffect(() => {
    form.setFieldsValue(initialValues)
    setAreaConditions({
      term: initialValues.term,
      type: initialValues.type,
    })
  }, [initialValues])

  return (
    <StyledForm
      className={results ? 'small-results' : ''}
      form={form}
      name="search"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <Row justify="space-between" gutter={20}>
        <Col span={8}>
          <Form.Item name="websites" getValueFromEvent={onDomainChange}>
            <Select mode="multiple" label="Look up Aldi website" showArrow>
              <Option key="all" value="all">
                All websites
              </Option>
              {snapshotDomains.map((domain) => (
                <Option key={domain.name} value={domain.name}>
                  {domain.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <p style={{ margin: '0 0 4px 0', fontSize: 12, color: '#808080' }}>
            Select period
          </p>
          <Form.Item name="period">
            <RangePicker
              allowClear={false}
              style={{ width: '100%', height: results ? 40 : 52 }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="term">
            <Input
              label={
                <>
                  With search term
                  <a
                    href="/api/v1/search-guidelines"
                    target="_blank"
                    style={{ marginLeft: 8 }}
                  >
                    <InfoCircleOutlined />
                  </a>
                </>
              }
              searchStyles
              style={{ width: '100%', lineHeight: results ? '30px' : '42px' }}
              suffix={<SearchOutlined />}
              onChange={onChangeTermAndType}
            />
          </Form.Item>
        </Col>
      </Row>
      <UnmountClosed isOpened={!collapsed}>
        <Row justify="space-between" gutter={20}>
          <StyledCol span={8}>
            <Form.Item name="area">
              <Select
                size="large"
                label="In page area"
                style={{ width: '100%' }}
                disabled={areaDisabled}
              >
                <Option value="special_buys">Special Buys</Option>
                <Option value="whole_website">Whole Website</Option>
              </Select>
            </Form.Item>
          </StyledCol>
          <StyledCol span={8}>
            <Form.Item name="language">
              <Select
                data-cy="language"
                label="In language"
                style={{ width: '100%' }}
                size="large"
              >
                <Option value="all">all</Option>
                {matchedLanguages.map(({ id, name, code }) => (
                  <Option key={id} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </StyledCol>
          <Col span={8}>
            <p
              style={{
                margin: '0 0 4px 0',
                fontSize: 12,
                color: '#808080',
              }}
            >
              File type
            </p>
            <Form.Item name="type">
              <StyledRadioGroup onChange={onChangeTermAndType}>
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="html">Html</Radio.Button>
                <Radio.Button value="doc">Doc</Radio.Button>
                <Radio.Button value="pdf">Pdf</Radio.Button>
              </StyledRadioGroup>
            </Form.Item>
          </Col>
        </Row>
      </UnmountClosed>
    </StyledForm>
  )
}
