import React from 'react'
import { Card } from '../../../Components/Card/Card'
import { UserDataForm } from '../Common/UserDataForm'

export const AddUser = () => {
  return (
    <>
      <h1>Add User</h1>
      <Card width="100%" padding="15px 30px" margin="30px 0">
        <UserDataForm />
      </Card>
    </>
  )
}
