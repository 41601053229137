import { makeUseAxios } from 'axios-hooks'
import Axios from 'axios'

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Access-Control-Allow-Origin': '*' },
  withCredentials: true,
})

export const useAxios = makeUseAxios({ axios })
