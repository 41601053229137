import React from 'react'
import { Alert, Form } from 'antd'
import { Input } from '../../Components/Form/Input'
import { StyledButton } from './styles'

export const LoginForm = ({ login, error }) => {
  const [form] = Form.useForm()

  return (
    <Form name="login" form={form} onFinish={login}>
      {error && (
        <Alert
          message={error.msg || 'Something went wrong.'}
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Please input your username' }]}
      >
        <Input label="Login" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password' }]}
      >
        <Input label="Password" type="password" />
      </Form.Item>
      <Form.Item>
        <StyledButton htmlType="submit">Log In</StyledButton>
      </Form.Item>
    </Form>
  )
}
