import React from 'react'
import moment from 'moment'
import { Alert, Table } from 'antd'
import { DomainName } from './DomainName'
import { StyledTable } from '../../styles'
import { useLanguages } from '../../hooks'
import { Metadata } from './Metadata'
import { SnapshotEndedDate } from './Dates'

const { Column } = Table

export const SchedulesHistory = ({
  data,
  loading,
  error,
  total,
  paginationState,
  setPaginationState,
}) => {
  const languages = useLanguages()
  const onPaginationChange = (page) => {
    const { current, pageSize } = page
    setPaginationState({ page: current, per_page: pageSize })
  }

  if (error) {
    return (
      <Alert
        message="An error occurred while fetching snapshots history."
        type="error"
        showIcon
        style={{ marginBottom: 15 }}
      />
    )
  }
  return (
    <StyledTable
      onChange={onPaginationChange}
      dataSource={data}
      loading={loading}
      pagination={{
        position: ['bottomLeft'],
        pageSize: paginationState.per_page,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: total,
      }}
      bordered
    >
      <Column
        title="Site"
        dataIndex="domain"
        key="domain"
        render={(value, row) => <DomainName row={row} languages={languages} />}
      />
      <Column
        title="Started"
        dataIndex="start_time"
        key="start_time"
        render={(value) => moment.unix(value).format('DD.MM.YYYY HH:mm')}
      />
      <Column
        title="Ended"
        dataIndex="stop_time"
        key="stop_time"
        render={(value) => <SnapshotEndedDate date={value} />}
      />
      <Column title="Type" dataIndex="type" key="type" />
      <Column title="Status" dataIndex="status" key="status" />
      <Column title="Crawler" dataIndex="crawler" key="crawler" />
      <Column
        title="Scheduled by"
        dataIndex="scheduled_by"
        key="scheduled_by"
      />
      <Column
        title="Metadata"
        key="metadata_path"
        dataIndex="metadata_path"
        render={(_, record) => <Metadata value={record} />}
      />
    </StyledTable>
  )
}
