import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { useUsers } from '../hooks'
import { StyledAlert } from '../styles'
import { DomainsList } from './DomainsList'
import { useSelector } from 'react-redux'
import { StyledTable } from '../../../styles'

export const UsersList = () => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [data, total, loading, error, refetch] = useUsers(
    pagination.current,
    pagination.pageSize
  )
  const { hash } = useSelector((state) => state.users)
  const columns = [
    {
      title: '',
      dataIndex: 'active',
      key: 'active',
      render: (value) =>
        value ? (
          <CheckCircleFilled style={{ color: '#3CB287' }} />
        ) : (
          <CloseCircleFilled style={{ color: '#F04124' }} />
        ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (value, row) => (
        <Link to={`/settings?userid=${row.key}`}>
          <Button type="link">{value}</Button>
        </Link>
      ),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Websites',
      dataIndex: 'domains_permissions',
      key: 'domains_permissions',
      render: (value) => <DomainsList domains={value} />,
    },
    {
      title: 'User manager',
      dataIndex: 'managing_users',
      key: 'managing_users',
      render: (value) => (
        <span style={{ color: '#222', fontSize: 14 }}>
          {value ? 'yes' : 'no'}
        </span>
      ),
    },
    {
      title: 'Warc access',
      dataIndex: 'warc_access',
      key: 'warc_access',
      render: (value) => (
        <span style={{ color: '#222', fontSize: 14 }}>
          {value ? 'yes' : 'no'}
        </span>
      ),
    },
  ]

  useEffect(() => {
    refetch()
  }, [hash, pagination])

  const onPaginationChange = (page) => {
    const { current, pageSize } = page
    setPagination({ ...pagination, current, pageSize })
  }

  return error ? (
    <StyledAlert
      message="An error occurred while fetching data."
      type="error"
      showIcon
    />
  ) : (
    <div>
      <StyledTable
        onChange={onPaginationChange}
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={{
          position: ['bottomLeft'],
          pageSize: pagination.pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          showQuickJumper: true,
          showSizeChanger: true,
          total: total,
        }}
        bordered
      />
    </div>
  )
}
