import React from 'react'
import { Col, Row } from 'antd'
import { v4 as uuid } from 'uuid'
import { CameraOutlined, CalendarOutlined } from '@ant-design/icons'

export const DomainsList = ({ domains }) =>
  domains.map((domain) => (
    <Row
      key={uuid()}
      style={{ color: '#222', fontSize: 14, margin: '5px 0' }}
      gutter={16}
      justify="space-between"
    >
      <Col span={17}>{domain.name}</Col>
      <Col span={7} style={{ paddingLeft: '3px', paddingRight: '3px' }}>
        <CameraOutlined
          style={{
            fontSize: 19,
            color: domain.snapshot_access ? '#898989' : '#E7E8E7',
            display: 'inline-block',
          }}
        />
        <CalendarOutlined
          style={{
            fontSize: 19,
            color: domain.schedule_manage ? '#898989' : '#E7E8E7',
            display: 'inline-block',
            marginLeft: '6px',
          }}
        />
      </Col>
    </Row>
  ))
