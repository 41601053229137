import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Menu } from 'antd'
import { SettingOutlined, UserOutlined } from '@ant-design/icons'
import { axios } from '../../axios'
import { REMOVE_USER_DATA } from '../../store/reducers/user'

export const UserMenu = () => {
  const dispatch = useDispatch()

  const logout = () => {
    axios({
      url: '/logout',
      method: 'post',
    }).then(() => {
      localStorage.removeItem('user')
      dispatch({ type: REMOVE_USER_DATA })
    })
  }

  return (
    <Menu>
      <Menu.Item key="1" icon={<SettingOutlined />}>
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      <Menu.Item onClick={logout} key="2" icon={<UserOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  )
}
