import React, { useState } from 'react'
import { Button } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import { axios } from '../../axios'

export const CancelButton = ({ id, refetch }) => {
  const [cancel, setCancel] = useState(null)
  const cancelSchedule = (id) => {
    axios({
      url: `/schedules/${id}`,
      method: 'DELETE',
    }).then(() => {
      refetch()
    })

    setCancel(null)
  }

  return (
    <>
      <div>
        <Button
          type="link"
          style={{
            textTransform: 'uppercase',
            fontSize: 12,
          }}
          onClick={() => setCancel(id)}
        >
          <span
            style={{
              marginRight: 6,
              transform: 'translateY(-1px)',
            }}
          >
            <CloseCircleFilled className="cancel-schedule" />
          </span>
          cancel schedule
        </Button>
      </div>
      {cancel === id ? (
        <div style={{ marginLeft: 18 }}>
          <span>Are you sure?</span>
          <Button
            style={{ padding: 4 }}
            type="link"
            onClick={() => cancelSchedule(id)}
          >
            Yes
          </Button>
        </div>
      ) : null}
    </>
  )
}
