export const theme = {
  primary: '#F86A06',
  button: '#2f6abb',
  buttonHover: '#2A60AB',
  buttonLight: '#346AB1',
  warning: '#faad14',
  success: '#a0d3e8',
  successBorder: '#74bfdd',
  error: '#F04124',
  errorLight: '#FBAEAE',
  link: '#4172AE',
  shadow: '0 1px 3px rgba(0, 0, 0, .15)',
  border: '1px solid #CDCDCD',
  background: '#EBEBEB',
  borderRadius: '4px',
}
