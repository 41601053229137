export const initialState = {
  managing: false,
  access: true,
  websites: [],
  warcs: false,
}
export const reducer = (state, action) => {
  switch (action.type) {
    case 'set_managing':
      return { ...state, managing: action.payload }
    case 'set_access':
      return { ...state, access: action.payload }
    case 'set_websites':
      return { ...state, websites: action.payload }
    case 'set_warc_access':
      return { ...state, warcs: action.payload }
    case 'reset':
      return initialState
    default:
      return state
  }
}