import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Form, Row, Select as AntdSelect } from 'antd'
import { v4 as uuid } from 'uuid'
import { useDomains } from '../../hooks'
import { Select } from '../../Components/Form/Select'
import { hours } from './helpers'
import { StyledButtonSmall } from '../Search/styles'
import { axios } from '../../axios'
import { required } from '../../Components/Form/rules'
import {
  SET_SUCCESS,
  SET_ERROR,
  SET_SUCCESS_MSG,
} from '../../store/reducers/submission'

const { Option } = AntdSelect

export const SchedulesForm = ({ refetch }) => {
  const [form] = Form.useForm()
  const { scheduleDomains } = useDomains()
  const dispatch = useDispatch()
  const [weekdayDisabled, setWeekdayDisabled] = useState(false)
  const [nowVisible, setNowVisible] = useState(true)
  const [loading, setLoading] = useState(false)

  const onValuesChange = (changed) => {
    if (changed.period && changed.period === 'daily') {
      setWeekdayDisabled(true)
    } else {
      setWeekdayDisabled(false)
    }

    if (changed.week_day) {
      setNowVisible(false)
    } else {
      setNowVisible(true)
    }
  }

  const onFinish = (values) => {
    const payload = Object.entries(values).reduce(
      (acc, [k, v]) => (!v ? { ...acc, [k]: null } : { ...acc, [k]: v }),
      {}
    )

    setLoading(true)

    axios({
      url: '/schedules',
      method: 'POST',
      data: { ...payload, timezone_offset: new Date().getTimezoneOffset() },
    })
      .then(() => {
        dispatch({ type: SET_SUCCESS })
        dispatch({
          type: SET_SUCCESS_MSG,
          payload: 'Schedule successfully added.',
        })
        setLoading(false)
        refetch()
      })
      .catch((error) => {
        dispatch({ type: SET_ERROR, payload: error.response.data.msg })
        setLoading(false)
      })

    form.resetFields()
  }

  return (
    <>
      <Form
        form={form}
        name="schedules"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="domain" rules={[required]}>
              <Select
                allowClear
                size="large"
                placeholder="choose"
                label="Choose website"
              >
                {scheduleDomains.length &&
                  scheduleDomains.map((domain) => (
                    <Option key={domain.id} value={domain.name}>
                      {domain.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="period" rules={[required]}>
              <Select
                allowClear
                size="large"
                placeholder="choose"
                label="Period"
              >
                <Option key={uuid()} value="daily">
                  Daily
                </Option>
                <Option key={uuid()} value="weekly">
                  Weekly
                </Option>
                <Option key={uuid()} value="once">
                  Once
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="week_day">
              <Select
                allowClear
                size="large"
                placeholder="choose"
                label="Week day"
                disabled={weekdayDisabled}
              >
                <Option key={uuid()} value="mo">
                  Monday
                </Option>
                <Option key={uuid()} value="tu">
                  Tuesday
                </Option>
                <Option key={uuid()} value="we">
                  Wednesday
                </Option>
                <Option key={uuid()} value="th">
                  Thursday
                </Option>
                <Option key={uuid()} value="fr">
                  Friday
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="crawler">
              <Select
                allowClear
                size="large"
                placeholder="choose"
                label="Crawler"
              >
                <Option key={uuid()} value="1">
                  HTTRACK
                </Option>
                <Option key={uuid()} value="3">
                  PYWB
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="run_time" rules={[required]}>
              <Select allowClear size="large" placeholder="choose" label="Time">
                {nowVisible ? (
                  <Option key={uuid()} value="now">
                    Now
                  </Option>
                ) : null}
                {hours.map((hour) => (
                  <Option key={uuid()} value={hour}>
                    {hour}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <StyledButtonSmall loading={loading} htmlType="submit">
              Schedule snapshot
            </StyledButtonSmall>
          </Col>
        </Row>
      </Form>
    </>
  )
}
