import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ErrorBoundary } from 'react-error-boundary'
import { ThemeProvider } from 'styled-components'

import { Navbar } from './Components/Navbar/Navbar'
import { AldiFooter } from './Components/Footer/Footer'
import { Login } from './Pages/Login/Login'
import { ErrorFallback } from './Components/ErrorFallback/ErrorFallback'
import { UserProvider } from './Pages/Login/UserProvider'
import { Protected } from './Pages/Login/Protected'
import { Users } from './Pages/UsersSettings/Users/Users'
import { AddUser } from './Pages/UsersSettings/Users/AddUser'
import { Settings } from './Pages/UsersSettings/Settings/Settings'
import { Search } from './Pages/Search/Search'
import { Schedules } from './Pages/Schedules/Schedules'
import { SearchResults } from './Pages/Search/SearchResults'
import { Snapshot } from './Pages/Snapshot/Snapshot'
import { SubmissionFeedback } from './Components/Form/SubmissionFeedback'
import { PermissionsProvider } from './Pages/Login/PermissionsProvider'
import { Public } from './Components/Public/Public'

import store from './store'
import { StyledContent, StyledHeader, StyledLayout } from './styles'
import { theme } from './theme'
import './index.css'

function App() {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <UserProvider>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <SubmissionFeedback />
                <Switch>
                  <Route path="/snapshot" component={Snapshot} />
                  <Route>
                    <StyledLayout>
                      <StyledHeader>
                        <Navbar />
                      </StyledHeader>
                      <StyledContent>
                        <Switch>
                          <Route path="/login" component={Login} />
                          <Route path="/search-public" component={Public} />
                          <Route>
                            <Protected>
                              <PermissionsProvider>
                                <Switch>
                                  <Route
                                    exact
                                    path="/schedules"
                                    component={Schedules}
                                  />
                                  <Route
                                    exact
                                    path="/users"
                                    component={Users}
                                  />
                                  <Route
                                    exact
                                    path="/users/add"
                                    component={AddUser}
                                  />
                                  <Route
                                    exact
                                    path="/settings"
                                    component={Settings}
                                  />
                                  <Route
                                    path="/search"
                                    component={SearchResults}
                                  />
                                  <Route exact path="/" component={Search} />
                                  <Route
                                    component={() => <Redirect to="/" />}
                                  />
                                </Switch>
                              </PermissionsProvider>
                            </Protected>
                          </Route>
                        </Switch>
                      </StyledContent>
                      <AldiFooter />
                    </StyledLayout>
                  </Route>
                </Switch>
              </ErrorBoundary>
            </UserProvider>
          </QueryParamProvider>
        </Router>
      </ThemeProvider>
    </ReduxProvider>
  )
}

export default App
