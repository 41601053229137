import React from 'react'
import { v4 as uuid } from 'uuid'
import { Select } from 'antd'
import { StyledSelect } from '../styles'

const { Option } = Select

export const WebsitesSelect = ({ onSelectChange, data, loading, ...rest }) => {
  return (
    <StyledSelect
      style={{ width: '100%' }}
      mode="multiple"
      placeholder="Add websites"
      onChange={onSelectChange}
      loading={loading}
      {...rest}
    >
      {data &&
        data.map((domain) => (
          <Option key={uuid()} value={domain.name}>
            {domain.name}
          </Option>
        ))}
    </StyledSelect>
  )
}
