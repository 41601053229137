import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import moment from 'moment'

export const useSearchQuery = () => {
  const searchParams = useSelector((state) => state.search)
  const isDomainsArray = (value) => Array.isArray(value) && value[0] === 'all'
  return Object.entries(searchParams)
    .filter(
      ([, value]) =>
        !(
          value === null ||
          typeof value === 'undefined' ||
          value === 'all' ||
          isDomainsArray(value)
        )
    )
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}

export const useInitialValues = (query = {}) => {
  const initial = {
    websites: ['all'],
    period: [moment().subtract(1, 'months'), moment()],
    area: 'special_buys',
    type: 'all',
    language: 'all',
  }

  const [initialValues, setInitialValues] = useState(initial)

  useEffect(() => {
    if (Object.keys(query).length) {
      setInitialValues({
        websites: !query.domains ? ['all'] : query.domains.split(','),
        period: [moment.unix(query.date_start), moment.unix(query.date_stop)],
        area: query.page_area || 'whole_website',
        type: query.file_type || 'all',
        language: query.lang || 'all',
        term: query.phrase,
      })
    }
  }, [query])

  return initialValues
}
