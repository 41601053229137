import React from 'react'
import { Tabs } from 'antd'
import { SchedulesForm } from './SchedulesForm'
import { SchedulesList } from './SchedulesList'
import { Card } from '../../Components/Card/Card'
import { useSchedules } from './hooks'
import { SchedulesHistory } from './SchedulesHistory'

const { TabPane } = Tabs

export const Schedules = () => {
  const { schedules, history } = useSchedules()
  const {
    data,
    loading,
    error,
    refetch,
    total,
    paginationState,
    setPaginationState,
  } = schedules
  const {
    historyData,
    historyLoading,
    historyError,
    historyTotal,
    historyPaginationState,
    setHistoryPaginationState,
    historyRefetch,
  } = history

  const onTabChange = (activeKey) => {
    if (activeKey === '1') {
      refetch()
    } else {
      historyRefetch()
    }
  }
  return (
    <>
      <SchedulesForm refetch={refetch} />
      <h1 style={{ margin: '15px 0 30px 0' }}>Schedules</h1>
      <Tabs defaultActiveKey="1" onChange={onTabChange}>
        <TabPane tab="scheduled" key="1">
          <Card width="100%" padding="30px 30px 15px 30px">
            <SchedulesList
              data={data}
              loading={loading}
              refetch={refetch}
              error={error}
              total={total}
              paginationState={paginationState}
              setPaginationState={setPaginationState}
            />
          </Card>
        </TabPane>
        <TabPane tab="history" key="2">
          <Card width="100%" padding="30px 30px 15px 30px">
            <SchedulesHistory
              data={historyData}
              loading={historyLoading}
              error={historyError}
              total={historyTotal}
              paginationState={historyPaginationState}
              setPaginationState={setHistoryPaginationState}
            />
          </Card>
        </TabPane>
      </Tabs>
    </>
  )
}
