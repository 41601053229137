export const SCREENSHOTS_ADD = 'SCREENSHOTS_ADD'
export const SCREENSHOTS_REMOVE = 'SCREENSHOTS_REMOVE'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case SCREENSHOTS_ADD:
      return [...new Set([...state, action.payload])]
    case SCREENSHOTS_REMOVE:
      return []
    default:
      return state
  }
}
