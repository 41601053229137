import styled from 'styled-components'
import { Alert, Button, Col, Form, Radio } from 'antd'
import logo from '../../assets/logo.png'

export const StyledCol = styled(Col)`
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 52px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input {
    height: 52px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 52px;
  }
`
export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    height: 52px;
    line-height: 52px;
    padding: 0 16px;
    margin-right: 8px;
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid #cdcdcd;
    text-transform: uppercase;
    font-weight: bold;

    &::before {
      display: none;
    }
  }
`

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.button};
  color: #fff;
  display: block;
  margin: 0 auto;
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  width: 100%;

  &.ant-btn {
    padding: 16px 0;
    height: auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.buttonHover};
    color: #fff;
  }

  &:focus {
    background-color: ${(props) => props.theme.buttonLight};
    color: #fff;
  }
`

export const StyledButtonSmall = styled(StyledButton)`
  font-size: 14px;
  width: 100%;

  &.ant-btn {
    padding: 12px 0;
    height: auto;
    line-height: 38px;
  }
`

export const StyledForm = styled(Form)`
  &.small-results {
    .ant-radio-button-wrapper {
      height: 38px;
      line-height: 38px;
      padding: 0 8px;
      margin-right: 6px;
      border-radius: ${(props) => props.theme.borderRadius};
      border: 1px solid #cdcdcd;
      text-transform: uppercase;
      font-weight: bold;

      &::before {
        display: none;
      }
      &.ant-radio-button-wrapper-checked {
        border-color: ${(props) => props.theme.primary};
      }
    }

    .ant-select-multiple .ant-select-selector {
      padding: 5px 10px;
      font-size: 14px;
    }

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      height: 40px;
      font-size: 14px;
    }

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
      .ant-select-selection-search-input {
      height: 40px;
      font-size: 14px;
    }

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-item,
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-placeholder {
      line-height: 40px;
      font-size: 14px;
    }

    .ant-form-item {
      margin-bottom: 6px;
    }
  }
`
export const StyledSuccess = styled(Alert)`
  border: 1px solid ${(props) => props.theme.successBorder};
  background-color: ${(props) => props.theme.success};
  margin: 50px 0;

  .anticon {
    color: ${(props) => props.theme.button};
  }
`
export const StyledSlider = styled.div`
  position: relative;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  background-clip: padding-box;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 45px;
  margin-bottom: 80px;
  display: block;
  height: 74px;

  .ant-slider {
    z-index: 3;
    position: relative;
    padding-top: 28px;

    .ant-slider-handle {
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }

    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      display: none;
    }

    .ant-slider-handle {
      cursor: ew-resize;
      border: none;
      background: transparent;
      width: 25px;
      height: 25px;
      top: 21px;
    }

    .ant-slider-handle-2 {
      left: -4px;
    }

    .ant-slider-handle-1 {
      right: -4px;
    }
  }

  .calendar {
    position: relative;
    display: inline-block;
    width: 96%;
  }
  .icon {
    display: inline-block;
    left: 0;
    top: 0;
    width: 4%;
    background: #f2f2f2;
    vertical-align: top;
    padding-top: 28px;
    text-align: center;
    height: 72px;
    border-radius: 4px 0 0 4px;
  }

  .days {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 74px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .day {
    flex: 1;
    text-align: center;
    height: 100%;
    border-left: 1px solid #cccccc;
    line-height: 74px;
    font-size: 12px;
    color: #808080;

    strong {
      color: #000000;
      font-weight: 500;
    }
  }

  .selected {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 74px;
    border: 1px solid #306cbf;
    background: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.3);

    &::before {
      content: '';
      position: absolute;
      background: #306cbf;
      width: 8px;
      height: 8px;
      border: none;
      border-radius: 2px;
      top: 33px;
      left: -4px;
    }

    &::after {
      content: '';
      position: absolute;
      background: #306cbf;
      width: 8px;
      height: 8px;
      border: none;
      border-radius: 2px;
      top: 33px;
      right: -4px;
    }
  }

  .months {
    display: block;
    position: absolute;
    height: 35px;
    top: -35px;
    left: 0;
    width: 100%;

    .month {
      display: inline-block;
      text-align: center;
      position: relative;
      height: 35px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;

      &:before {
        content: '';
        bottom: 0px;
        border-top: 1px solid #808080;
        border-radius: 5px;
        height: 10px;
        position: absolute;
        width: 100%;
        left: 0;
      }

      &:after {
        content: '';
        width: 6px;
        height: 6px;
        border-right: 1px solid #808080;
        border-top: 1px solid #808080;
        transform: rotate(-45deg);
        position: absolute;
        left: 50%;
        bottom: 7px;
        background: #ebebeb;
        z-index: 1;
      }
    }
  }
`
export const StyledPlaceholder = styled.div`
  width: 90px;
  height: 52px;
  margin: 0 20px 5px 0;
  background: #ccc;
  display: inline-block;
  vertical-align: top;
`

export const StyledImage = styled.div`
  display: block;
  width: 276px;
  height: 260px;
  overflow: hidden;
  margin: 0 auto;
  background: #fff url(${logo}) 50% 50% no-repeat;
  position: relative;

  img {
    width: 276px;
    height: 552px;
  }

  &.expanded {
    width: 100%;
    height: auto;
    min-height: 600px;

    img {
      width: 453px;
      height: 906px;
    }
  }
`

export const StyledExcerpt = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: gray;
  margin-bottom: 0;

  em {
    font-weight: 600;
    color: #111;
    font-style: normal;
  }
`
