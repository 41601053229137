import styled from 'styled-components'
import { Button } from 'antd'

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.button};
  color: #fff;
  display: block;
  margin: 0 auto;
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  width: 60%;

  &.ant-btn {
    padding: 16px 0;
    height: auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.buttonHover};
    color: #fff;
  }

  &:focus {
    background-color: ${(props) => props.theme.buttonLight};
    color: #fff;
  }
`

export const StyledLogoContainer = styled.div`
  text-align: center;
  margin-top: 80px;

  h1 {
    margin-bottom: 0;
    margin-top: 25px;
  }

  span {
    font-size: 14px;
  }
`
