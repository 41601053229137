import React from 'react'
import { Button, Col, Row } from 'antd'
import { Card } from '../Card/Card'
import logo from '../../assets/logo.png'

export function ErrorFallback({ error, componentStack, resetErrorBoundary }) {
  return (
    <Row
      align="middle"
      justify="center"
      style={{ height: '100vh', width: '100vw', flexDirection: 'column' }}
    >
      <Card width="50%" padding="50px">
        <Col>
          <img
            src={logo}
            alt="Aldi Logo"
            style={{ display: 'block', margin: '0 auto 30px auto' }}
          />
        </Col>
        <Col>
          <p style={{ fontSize: 18, textAlign: 'center' }}>
            Something went wrong.
          </p>
          <Button
            style={{
              display: 'block',
              margin: '0 auto',
            }}
            type="default"
            onClick={resetErrorBoundary}
          >
            Try again
          </Button>
        </Col>
      </Card>
    </Row>
  )
}
