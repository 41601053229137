import React, { useEffect, useState } from 'react'
import { Button, Popover } from 'antd'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { axios } from '../../axios'
import { SET_ERROR } from '../../store/reducers/submission'

const StyledContent = styled.div`
  color: #000;
  white-space: nowrap;
  overflow-y: scroll;
  background: #fff;
  padding-top: 2px;

  code {
    border: 1px solid #ccc;
    border-radius: ${(props) => props.theme.borderRadius};
    padding: 2px 4px;
  }
`

const MetadataLink = ({ to }) => (
  <a
    href={`${process.env.NODE_ENV === 'production' ? window.env.SNAPSHOT_ROOT : process.env.REACT_APP_SNAPSHOT_ROOT}${to}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {to}
  </a>
)

const Content = ({ data }) => {
  const dispatch = useDispatch()
  const [recalcRequested, setRecalcRequested] = useState(false)
  const recalculate = () => {
    axios({
      url: `/calc_checksum`,
      method: 'POST',
      data: {
        snapshot_id: data.id,
      },
    })
      .then(() => {
        setRecalcRequested(true)
      })
      .catch((error) =>
        dispatch({ type: SET_ERROR, payload: error.response.data.msg })
      )
  }

  useEffect(() => {
    if (data.sha256_calc_requested) {
      setRecalcRequested(true)
    }
  }, [])

  return (
    <StyledContent>
      <p>
        Text file:{' '}
        <code>
          <MetadataLink to={data.metadata_path} />
        </code>
      </p>
      <p>Glacier status: {data.trustee_upload_status || 'n/a'}</p>
      <p>
        Glacier Object ID: <code>{data.trustee_object_id || 'n/a'}</code>
      </p>
      <p>
        Checksum{' '}
        {!recalcRequested && (
          <Button
            type="link"
            onClick={recalculate}
            style={{ padding: 0, margin: 0, height: '14px' }}
          >
            (recalculate)
          </Button>
        )}
        : <code>{data.sha256 || 'n/a'}</code>
      </p>
      <p>
        Last calculation:{' '}
        {data.sha256_calc_date
          ? moment(data.sha256_calc_date * 1000).format('DD.MM.YYYY HH:mm')
          : 'n/a'}
      </p>
    </StyledContent>
  )
}

export const Metadata = ({ value }) => {
  return (
    <Popover
      color="#fff"
      placement="topRight"
      overlayStyle={{
        overflow: 'hidden',
        width: 500,
      }}
      title="Metadata"
      content={<Content data={value} />}
      trigger={'hover'}
    >
      <Button type="link">Details</Button>
    </Popover>
  )
}
