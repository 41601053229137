import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { axios } from '../../../axios'
import { Card } from '../../../Components/Card/Card'
import { UserDataForm } from '../Common/UserDataForm'
import { ActivityHistory } from './ActivityHistory'
import { Domains } from './Domains'
import { useQuery } from '../../../hooks'

const { TabPane } = Tabs

export const Settings = () => {
  let query = useQuery().get('userid')
  const { hash } = useSelector((state) => state.users)
  const [userSettings, setUserSettings] = useState({})
  const currentUser = useSelector((state) => state.user)

  useEffect(() => {
    if (query) {
      axios({
        url: `/users/${query}`,
        method: 'GET',
      }).then((response) => {
        setUserSettings(response.data)
      })
    } else {
      setUserSettings(currentUser)
    }
  }, [query, hash])

  return (
    <>
      <h1>{`${userSettings.username} settings`}</h1>
      <div style={{ margin: '30px 0' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="account settings" key="1">
            <Card width="100%" padding="15px 30px">
              {Object.keys(userSettings).length ? (
                <UserDataForm settingsData={userSettings} />
              ) : (
                <LoadingOutlined />
              )}
            </Card>
          </TabPane>
          <TabPane tab="activity history" key="2">
            <Card width="100%" padding="15px 30px">
              <ActivityHistory settingsData={userSettings} />
            </Card>
          </TabPane>
          <TabPane tab="domains" key="3">
            <Card width="100%" padding="15px 30px">
              <Domains />
            </Card>
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}
