import styled from 'styled-components'
import { Button, Layout, Table } from 'antd'

const { Header, Content } = Layout

export const StyledHeader = styled(Header)`
  padding: 0;
  height: auto;
  background: #ebebeb;
`
export const StyledLayout = styled(Layout)`
  background: #ebebeb;
`

export const StyledButton = styled(Button)`
  border: 1px solid #c6c6c6;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: ${(props) => props.theme.shadow};
  background-color: rgba(120, 120, 120, 0.02);
  text-transform: uppercase;
  color: #3f3f3f;
  font-weight: bold;
  margin: 20px 0;

  .add {
    padding-left: 15px;
    border-left: 1px solid #c6c6c6;
  }
`

export const StyledContent = styled(Content)`
  width: 1000px;
  margin: 30px auto;
`
export const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    text-transform: uppercase;
    background-color: #f5f5f5;
  }

  .anticon {
    font-size: 9px;
  }

  .anticon-check-circle {
    color: ${(props) => props.theme.success};
    display: block;
    margin: 0 auto;
  }

  .anticon-close-circle:not(.cancel-schedule) {
    color: ${(props) => props.theme.error};
    display: block;
    margin: 0 auto;
  }

  .ant-table-row:nth-child(even) {
    background-color: #f9f9f9;
  }

  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: #808080;
  }

  td.ant-table-cell {
    font-size: 14px;
    color: #222;

    button {
      font-size: 14px;
    }
  }

  .lighter {
    color: #808080;
  }
`
