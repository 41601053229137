import React from 'react'
import { Col, Row, Select as AntdSelect } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Select } from '../../../Components/Form/Select'
import { Input } from '../../../Components/Form/Input'

const { Option } = AntdSelect

export const ActivityFilters = ({ data }) => {
  const { types, updateFilters, websites } = data
  return (
    <Row>
      <Col span={6}>
        <Select
          size="large"
          label="Choose activity type"
          style={{ width: '90%' }}
          loading={types.loading}
          onChange={(value) => updateFilters({ type: value })}
          allowClear
        >
          {types.data &&
            types.data.map(({ type, label }) => (
              <Option key={type} value={label}>
                {label}
              </Option>
            ))}
        </Select>
      </Col>
      <Col span={6}>
        <Select
          size="large"
          label="Choose website"
          style={{ width: '90%' }}
          loading={websites.loading}
          onChange={(value) => updateFilters({ site: value })}
          allowClear
        >
          {websites.data &&
            websites.data.map(({ id, name }) => (
              <Option key={id} value={name}>
                {name}
              </Option>
            ))}
        </Select>
      </Col>
      <Col span={6}>
        <Input
          label="Username"
          searchStyles
          style={{ width: '90%', lineHeight: '30px' }}
          suffix={<SearchOutlined />}
          onChange={(event) => updateFilters({ phrase: event.target.value })}
        />
      </Col>
    </Row>
  )
}
