import React from 'react'
import { Alert, Table } from 'antd'
import { StyledTable } from '../../styles'
import { useLanguages } from '../../hooks'
import { CancelButton } from './CancelButton'
import { DomainName } from './DomainName'
import { NextRunDate } from './Dates'

const { Column } = Table

export const SchedulesList = ({
  data,
  loading,
  refetch,
  error,
  total,
  paginationState,
  setPaginationState,
}) => {
  const languages = useLanguages()
  const onPaginationChange = (page) => {
    const { current, pageSize } = page
    setPaginationState({ page: current, per_page: pageSize })
  }

  if (error) {
    return (
      <Alert
        message="An error occurred while fetching schedules."
        type="error"
        showIcon
        style={{ marginBottom: 15 }}
      />
    )
  }

  return (
    <StyledTable
      onChange={onPaginationChange}
      dataSource={data}
      loading={loading}
      pagination={{
        position: ['bottomLeft'],
        pageSize: paginationState.per_page,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: total,
      }}
      bordered
    >
      <Column
        title="Site"
        dataIndex="domain"
        key="domain"
        render={(value, row) => <DomainName row={row} languages={languages} />}
      />
      <Column
        title="Scheduled on"
        dataIndex="scheduled_on"
        key="scheduled_on"
      />
      <Column
        title="Next run"
        dataIndex="next_run"
        key="next_run"
        render={(value) => <NextRunDate date={value} />}
      />
      <Column
        title="Crawler"
        dataIndex="crawler"
        key="crawler"
      />
      <Column
        title="Scheduled by"
        dataIndex="scheduled_by"
        key="scheduled_by"
      />
      <Column
        title="Actions"
        key="actions"
        render={(value, row) => <CancelButton id={row.id} refetch={refetch} />}
      />
    </StyledTable>
  )
}
