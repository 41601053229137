import React from 'react'
import { Switch, Table } from 'antd'
import { StyledTable } from '../../../styles'

const { Column } = Table

export const WebsitesTable = ({ websites, onPermissionChange }) => {
  return (
    <StyledTable
      dataSource={websites}
      pagination={false}
      bordered
      locale={{
        emptyText: 'Choose websites from below and add them to the list.',
      }}
    >
      <Column title="Website" dataIndex="name" key="name" />
      <Column
        title="Snapshots Access"
        dataIndex="snapshot_access"
        key="snapshot_access"
        render={(value, row) => (
          <Switch
            defaultChecked={value}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(newValue) =>
              onPermissionChange(row, newValue, 'snapshot_access')
            }
          />
        )}
      />
      <Column
        title="Scheduling Snapshots"
        dataIndex="schedule_manage"
        key="schedule_manage"
        render={(value, row) => (
          <Switch
            defaultChecked={value}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(newValue) =>
              onPermissionChange(row, newValue, 'schedule_manage')
            }
          />
        )}
      />
    </StyledTable>
  )
}
