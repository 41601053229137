import { useEffect, useState } from 'react'
import { useAxios } from '../../axios'
import { normalizeHistory, normalizeUsers } from './helpers'

export const useUsers = (page, per_page) => {
  const [source, setSource] = useState([])
  const [total, setTotal] = useState(null)
  const [{ data, loading, error }, refetch] = useAxios({
    url: '/users',
    method: 'GET',
    params: {
      page,
      per_page,
    },
  })

  useEffect(() => {
    if (data) {
      setSource(normalizeUsers(data.objects))
      setTotal(data.num_results)
    }
  }, [data])

  return [source, total, loading, error, refetch]
}

export const useUserActivityData = (user_id) => {
  const [source, setSource] = useState([])
  const [{ data, loading, error }] = useAxios({
    url: '/snapshots',
    method: 'GET',
    params: { user_id },
  })

  const [{ data: languages, loading: languagesLoading }] = useAxios({
    url: '/languages',
    method: 'GET',
  })

  useEffect(() => {
    if (data) {
      setSource(normalizeUsers(data.objects))
    }
  }, [data])

  return {
    snapshots: { data: source, loading, error },
    languages: { data: languages, loading: languagesLoading },
  }
}

export const useActivityData = () => {
  const [filters, setFilters] = useState({ type: '', site: '', phrase: '' })
  const { type, site, phrase } = filters
  const [source, setSource] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })

  const [{ data: history, loading, error }] = useAxios({
    url: '/activity_history',
    method: 'GET',
    params: {
      page: pagination.current,
      per_page: pagination.pageSize,
    },
  })

  const [{ data: activityTypes, loading: activityLoading }] = useAxios({
    url: '/activity_types',
    method: 'GET',
  })

  const [{ data: websites, loading: websitesLoading }] = useAxios({
    url: '/domains',
    method: 'GET',
  })

  const [{ data: languages, loading: languagesLoading }] = useAxios({
    url: '/languages',
    method: 'GET',
  })

  useEffect(() => {
    if (history) {
      setSource(normalizeHistory(history.objects))
    }
  }, [history])

  const updateFilters = (newSettings) =>
    setFilters({ ...filters, ...newSettings })

  const filtered = source.filter((activity) => {
    const typeMatched = !type || activity.activity_type === type
    const siteMatched = !site || activity.domain === site
    const phaseMatched = !phrase || activity.username.includes(phrase)

    return typeMatched && siteMatched && phaseMatched
  })

  return {
    history: {
      data: filtered,
      loading,
      error,
      pagination,
      setPagination,
      total: history && history.num_results,
    },
    types: {
      data: activityTypes,
      loading: activityLoading,
    },
    websites: {
      data: websites,
      loading: websitesLoading,
    },
    languages: {
      data: languages,
      loading: languagesLoading,
    },
    updateFilters,
  }
}
