import React from 'react'
import { Link, Route, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  DownOutlined,
  ScheduleOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Dropdown } from 'antd'
import {
  StyledButton,
  StyledItem,
  StyledLogoContainer,
  StyledNavbar,
  StyledUserInfo,
} from './styles'
import { UserMenu } from './UserMenu'
import logo from '../../assets/logo.png'
import { usePermissions } from '../../hooks'

export const Navbar = () => {
  const user = useSelector(({ user }) => user)
  const username = user ? user.username : ''
  const [isAdmin, isScheduler] = usePermissions()

  const { pathname } = useLocation()
  const nav = [
    {
      key: '2',
      link: '/schedules',
      title: 'Schedules',
      icon: <ScheduleOutlined />,
      available: isScheduler,
    },
    {
      key: '3',
      link: '/users',
      title: 'Users',
      icon: <UserOutlined />,
      available: isAdmin,
    },
    {
      key: '4',
      link: '/',
      title: 'Aldi Wayback Search',
      icon: <SearchOutlined />,
      available: true,
    },
  ]

  const availableNav = nav.filter((navItem) => navItem.available)
  const activeItem = availableNav.find((navItem) => navItem.link === pathname)
  const activeKey = activeItem ? activeItem.key : null

  return (
    <Route
      exact
      path={['/schedules', '/users', '/users/add', '/settings', '/search', '/']}
    >
      <StyledNavbar mode="horizontal" selectedKeys={[activeKey]}>
        <StyledLogoContainer className="logo-container">
          <Link to="/">
            <img className="logo" src={logo} alt="Aldi Logo" />
          </Link>
        </StyledLogoContainer>
        {availableNav.map((navItem, index) => (
          <StyledItem key={index + 2} icon={navItem.icon}>
            <Link to={navItem.link}>
              <span>{navItem.title}</span>
            </Link>
          </StyledItem>
        ))}
        <StyledUserInfo>
          <Dropdown overlay={<UserMenu />}>
            <StyledButton>
              Logged in as: <span className="username">{username}</span>{' '}
              <DownOutlined />
            </StyledButton>
          </Dropdown>
        </StyledUserInfo>
      </StyledNavbar>
    </Route>
  )
}
